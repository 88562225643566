

I18n.defaultLocale = "fr";

export const LeafletConfig = {
  markerIconUrl: "/assets/v21/icons/pin-pink-54705633649d7219a02af9c0ed1686b949bb5e9363a7d929cdcc6b7333893390.svg"
};

export const MeConfig = {
  stripe: {
    publicKey: "pk_live_51LDrHeF8hBqvIjcaDiZFtm5ot9b20E1SBpnil3cjqLFLkOGTnr8Cw2HQM9Q3TavmyPgrfPYsMLgqReHysGImP5Ho000FPBJ5uz"
  }
};