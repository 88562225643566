#
# Meeting Engine - Geolocation
#

$.me = $.me || {}

$.extend true, $.me, {
  geolocation:
    watcher: null
    firstWatch: true
    position:
      lat: null
      lng: null

    watch: ->
      $(document).trigger 'me-before-geolocalize'

      unless $.me.geolocation.watcher
        $.me.geolocation.watcher = navigator.geolocation.watchPosition(((response) ->
          position = $.me.geolocation.position

          position.lat = response.coords.latitude
          position.lng = response.coords.longitude

          $(document).trigger 'me-geolocalize', [
            true
            {
              lat: position.lat,
              lng: position.lng
            }
            $.me.geolocation.firstWatch
          ]
          $.me.geolocation.firstWatch = false

          # console.log 'Watch location :: lat: ' + position.lat + ' - lng: ' + position.lng
          return
        ), $.me.geolocation.errors)

    unwatch: ->
      if $.me.geolocation.watcher != null
        navigator.geolocation.clearWatch $.me.geolocation.watcher

        @position.lat = null
        @position.lng = null
        @firstWatch   = true
        @watcher      = null

      $(document).trigger 'me-geolocalize', [
        false, {
          lat: @position.lat,
          lng: @position.lng
        }
        false
      ]

    get: ->
      navigator.geolocation.getCurrentPosition(((response) ->
        position = $.me.geolocation.position

        position.lat = response.coords.latitude
        position.lng = response.coords.longitude

        $(document).trigger 'me-geolocalize', [
          true, {
            lat: position.lat
            lng: position.lng
          },
          $.me.geolocation.firstWatch
        ]
      ), $.me.geolocation.errors)

    setHeaders: (xhr) ->
      if $.me.store.state?.config && $.me.store.state.config.location &&
         $.me.store.state.config.location.lat? && $.me.store.state.config.location.lng?
        xhr.setRequestHeader 'user-lat', $.me.store.state.config.location.lat
        xhr.setRequestHeader 'user-lng', $.me.store.state.config.location.lng
      return

    errors: (error) ->
      position = $.me.geolocation.position

      switch error.code
        when error.PERMISSION_DENIED
          $.me.geolocation.watcher  = null
          position.lat = null
          position.lng = null

          # console.log 'Geolocalize denied'
          $(document).trigger 'me-geolocalize-denied'
          $(document).trigger 'me-geolocalize', [
            false, {
              lat: position.lat
              lng: position.lng
            }
          ]
        when error.POSITION_UNAVAILABLE
          # position.lat = null;
          # position.lng = null;

          # console.log 'Geolocalize unvalaible'
          $(document).trigger 'me-geolocalize-unvalaible', [
            false, {
              lat: position.lat
              lng: position.lng
            },
            $.me.geolocation.firstWatch
          ]
          #$.me.geolocation.unwatch()
        when error.TIMEOUT
          # console.log 'Geolocalize timeout'
          $(document).trigger 'me-geolocalize-timeout'
          $(document).trigger 'me-geolocalize', [
            true, {
              lat: position.lat
              lng: position.lng
            }
          ]
        when error.UNKNOWN_ERROR
          # console.log 'Geolocalize unknow error'
          position.lat = null
          position.lng = null

          $(document).trigger 'me-geolocalize-unknow-error'
          $(document).trigger 'me-geolocalize', [
            false, {
              lat: position.lat
              lng: position.lng
            }
          ]
}

$(document).on 'turbolinks:request-start', (event) ->
  $.me.geolocation.setHeaders event.originalEvent.data.xhr
  return

$(document).on 'ajax:beforeSend', (event, xhr) ->
  $.me.geolocation.setHeaders event.originalEvent.detail[0]
  return
